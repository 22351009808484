import React from 'react'
import { Layout, Parallax } from '@components/layout'
import { ProductItem } from '@components/ecommerce'

import {
cart__wrapp
} from './styles/simpleCart.module.scss'

const renderWithAllProps = (Comp) => (entry, key) =>{
	return (<Comp {...entry} key={key}/>)
}

const SingleCategory = ({ pageContext }) => {
	const { products, page } = pageContext
	const { name, description } = page
	return (
		<Layout {...page}
		>
			<Parallax height={400} image={page.featuredImage} 
				title={name} subtitle={description}>
			</Parallax>

			<div className={cart__wrapp}>			
				{products.map(renderWithAllProps(ProductItem))}
				</div>
			
		</Layout>
	)
}

export default SingleCategory
